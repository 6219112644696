import axios from 'axios';

import { User } from './types';

const LOGIN = `https://cbb6ap3vdudfuehsyh7qt3e7vq0dqbyw.lambda-url.us-east-1.on.aws`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}

class LoginApi {

  public login = async (user: User) => {
    try {
      return axios.post(`${LOGIN}`,user, { headers });
    } catch (e) {
      throw e;
    }
  }

}
export default LoginApi;