import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ReactGA from 'react-ga';

import Contact from './modules/home/contact';
import Home from './modules/home/home';
import Login from './modules/home/login';
import Main from './modules/main';
import './app.css';

function App() {


  return (
    <div className="App">
      <div className="appBody">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/main" element={<Main />} />
          </Routes>          
        </Router>
      </div>
    </div>
  );

}

export default App;
