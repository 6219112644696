import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import logo from '../../images/logo.png';
import Button from '@material-ui/core/Button';

import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';

import './index.css';

const translate = (key: string) => {
  if (key === 'home.logout') {
      return 'Logout';
  }
}


const MainPage = () => {

  const [error, setError] = useState('');
  const [isSubmitting] = useState(false);
  const navigate = useNavigate();


  const submitLogout = async () => {
    try {
      localStorage.setItem('OrgId', '');
      localStorage.setItem('token', '');
      navigate(`/`);
    } catch (err) {
      throw Error('Unable to submit logout');
    }
  };

  return (
    <div className="wrapper">
      <div className="header">
        <div>
          <img src={logo} />
          <Button onClick={() => submitLogout()}>
          {translate('home.logout')}
          </Button>
        </div>
      </div>
      <div className="body">
        <div className="contentWrapper">
          <div className="content">
          <Tabs className="wrapper" defaultValue={0}>
            <TabList className="tablist">
              <Tab className="tablist">Tasks</Tab>
              <Tab className="tablist">Prompts</Tab>
              <Tab className="tablist">Filters</Tab>
              <Tab className="tablist">ApiKeys</Tab>
            </TabList>
            <TabPanel className="tablist" value={0}>

            </TabPanel>
          </Tabs>
          </div>
        </div>
        <div className="footer">
          <br />
          <br />
          <div className="footer">
          <span>© searchagent.co 2024. All rights reserved. </span>
          <br />
          </div>
          <br />
          <a href={process.env.PUBLIC_URL + '/privacy-policy.html'}
            className="footerLeftLink"
            target="_blank">
            <span>Privacy</span>
          </a>

          <a href={process.env.PUBLIC_URL + '/terms-of-use.html'}
          className="footerRightLink"
          target="_blank">
            <span>Terms</span>
          </a>

        </div>
      </div>
    </div>
  );
};

export default MainPage;
